import React from 'react';


import './styles.css';

const Contact: React.FC = () => {
  return (
    <div id="page-contact-container">
      <h2>CONTATO</h2> 
    </div>
  );
}

export default Contact;
